import { template as template_870da2391c2d41cb86568513d5bb3bc6 } from "@ember/template-compiler";
const FKText = template_870da2391c2d41cb86568513d5bb3bc6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
