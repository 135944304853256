import { template as template_c27a87788e5b43cb9dfc086903c50a50 } from "@ember/template-compiler";
const WelcomeHeader = template_c27a87788e5b43cb9dfc086903c50a50(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
