import { template as template_292b79226b3a4d59966b0f6978e5a27c } from "@ember/template-compiler";
const FKControlMenuContainer = template_292b79226b3a4d59966b0f6978e5a27c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
